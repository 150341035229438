import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';
import RolePermissions from '../role-permissions.js';

class RoleCatalogueModelSchema extends NovaSchema {
  constructor() {
    super('roleCatalogue');
    this.attributes = {
      roleId: new SchemaAttribute(),
      tenantId: new SchemaAttribute(),
      roleName: new SchemaAttribute(),
      description: new SchemaAttribute(),
      createdDate: new SchemaAttribute(),
      modifiedDate: new SchemaAttribute(),
    };
    this.setAllowExtraAttributes();
    this.modelAttributes = {
      rolePermissions: new SchemaAttribute([]),
    };
  }
}

export const RoleCatalogueSchema = new RoleCatalogueModelSchema();
