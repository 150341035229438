import { NovaSchema, SchemaAttribute } from './nova-model-schema.js';

class RolePermissionsModelSchema extends NovaSchema {
  constructor() {
    super('rolePermissions');
    this.attributes = {
      roleId: new SchemaAttribute(),
      permissionsId: new SchemaAttribute(),
      createdDate: new SchemaAttribute(new Date().toISOString()),
      modifiedDate: new SchemaAttribute(),
    };
    this.setAllowExtraAttributes();
  }
}

export const RolePermissionsSchema = new RolePermissionsModelSchema();
