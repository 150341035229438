import { NovaModel } from './nova-model.js';
import { RoleCatalogueSchema } from './schema/role-catalogue.js';

export default class RoleCatalogue extends NovaModel {
  constructor(base = {}) {
    super('roleCatalogue', base);
  }

  getSchema() {
    return RoleCatalogueSchema;
  }

  // TODO: We need to populate the rolePermissions array at runtime at the model level once the Permissions enum is defined

  // async populateRolePermissions() {
  //   const rolePermissions = await rolePermissionRepo.getAllPermissionsForRole(this.context, this.roleId);

  //   return rolePermissions;
  // }

  // async getRolePermissions() {
  //   if (!this.rolePermissions) {
  //     this.rolePermissions = await this.populateRolePermissions();
  //   }

  //   console.log(`what is this.rolePermissions: ${this.rolePermissions}`);
  // }
}
